import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiGateway } from '../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../interfaces';
import {
  MfaBackupCodeRequest,
  MfaBackupCodeResponse,
  MfaDisableRequest,
  MfaEnableRequest,
  MfaLoginRequest,
  MfaLoginWithOTPRequest,
  MfaResponse,
  MfaSetupRequest,
  MfaSetupResponse,
  MfaUpdateBackupEmailRequest,
  MfaVerifyBackupEmailRequest,
} from './mfa.model';

@Injectable()
export class MfaApi {
  private endpoint = 'multifactor/';

  public constructor(private gateway: ApiGateway) {}

  public setup(mfa: MfaSetupRequest): Observable<MfaSetupResponse> {
    return this.gateway.post(this.endpoint + 'setup', mfa);
  }

  public enable(mfa: MfaEnableRequest): Observable<MfaResponse> {
    return this.gateway.post(this.endpoint + 'enable', mfa);
  }

  public disable(mfa: MfaDisableRequest): Observable<MfaResponse> {
    return this.gateway.post(this.endpoint + 'disable', mfa);
  }

  public disableForUser(userId: string): Observable<MfaResponse> {
    return this.gateway.get(this.endpoint + 'disable/' + userId);
  }

  public login(mfa: MfaLoginRequest, dispatchStart?: Action): Observable<void> {
    return this.gateway.post(`${this.endpoint}challenge`, mfa, undefined, dispatchStart);
  }

  public loginWithOTP(mfa: MfaLoginWithOTPRequest, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint + 'email', mfa, undefined, dispatchStart);
  }

  public sendEmailWithOTPCode(): Observable<any> {
    return this.gateway.get(this.endpoint + 'email');
  }

  public getBackupCode(mfa: MfaBackupCodeRequest): Observable<MfaBackupCodeResponse> {
    return this.gateway.post(this.endpoint + 'backup', mfa);
  }

  public verifyBackupCode(mfa: MfaBackupCodeResponse): Observable<any> {
    return this.gateway.post(`${this.endpoint}verify_backup_code`, mfa);
  }

  public updateBackupEmail(mfa: MfaUpdateBackupEmailRequest): Observable<any> {
    return this.gateway.post(this.endpoint + 'email/update', mfa);
  }

  public verifyBackupEmail(mfa: MfaVerifyBackupEmailRequest): Observable<any> {
    return this.gateway.post(this.endpoint + 'email/verify', mfa);
  }

  public updatePromoteState(): Observable<any> {
    return this.gateway.post(this.endpoint + 'promote', null);
  }

  public refreshBackupCode() {
    return this.gateway.get(this.endpoint + 'refresh/backup_code');
  }
}
